document.documentElement.style.setProperty(
  '--scrollbar-width',
  window.innerWidth - document.documentElement.clientWidth + 'px',
);
/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/Push';
import '@/polyfills/Append';
import '@/polyfills/isNaN';
// import '@/polyfills/ObjectFit';

import 'alpinejs/dist/alpine-ie11';
/** Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/unicons.css';

/* Auto Imports */
import '@/auto-imports';

/* Import Styles */
import '@/styles/main.scss';

const ElementQueries = require('css-element-queries/src/ElementQueries');
ElementQueries.listen();
ElementQueries.init();
