if (!Array.prototype.push) {
// Check if not already supported, then only add. No need to check this when you want to Override the method

    // Add method to prototype of array, so that can be directly called on array
    Array.prototype.push = function() {
        // Use loop for multiple/any no. of elements
        for (var i = 0; i < arguments.length; i++) {
            this[this.length] = arguments[i];
        }


        // Return new length of the array
        return this.length;
    };
}
