const defaultColors = {
  transparent: 'transparent',
  current: 'currentColor',

  black: '#000',
  white: '#fff',
  gray: {
    100: '#f7fafc',
    200: '#edf2f7',
    300: '#e2e8f0',
    400: '#cbd5e0',
    500: '#a0aec0',
    600: '#718096',
    700: '#4a5568',
    800: '#2d3748',
    900: '#1a202c',
  },
  red: {
    100: '#fff5f5',
    200: '#fed7d7',
    300: '#feb2b2',
    400: '#fc8181',
    500: '#f56565',
    600: '#e53e3e',
    700: '#c53030',
    800: '#9b2c2c',
    900: '#742a2a',
  },
  orange: {
    100: '#fffaf0',
    200: '#feebc8',
    300: '#fbd38d',
    400: '#f6ad55',
    500: '#ed8936',
    600: '#dd6b20',
    700: '#c05621',
    800: '#9c4221',
    900: '#7b341e',
  },
  yellow: {
    100: '#fffff0',
    200: '#fefcbf',
    300: '#faf089',
    400: '#f6e05e',
    500: '#ecc94b',
    600: '#d69e2e',
    700: '#b7791f',
    800: '#975a16',
    900: '#744210',
  },
  green: {
    100: '#f0fff4',
    200: '#c6f6d5',
    300: '#9ae6b4',
    400: '#68d391',
    500: '#48bb78',
    600: '#38a169',
    700: '#2f855a',
    800: '#276749',
    900: '#22543d',
  },
  teal: {
    100: '#e6fffa',
    200: '#b2f5ea',
    300: '#81e6d9',
    400: '#4fd1c5',
    500: '#38b2ac',
    600: '#319795',
    700: '#2c7a7b',
    800: '#285e61',
    900: '#234e52',
  },
  blue: {
    100: '#ebf8ff',
    200: '#bee3f8',
    300: '#90cdf4',
    400: '#63b3ed',
    500: '#4299e1',
    600: '#3182ce',
    700: '#2b6cb0',
    800: '#2c5282',
    900: '#2a4365',
  },
  indigo: {
    100: '#ebf4ff',
    200: '#c3dafe',
    300: '#a3bffa',
    400: '#7f9cf5',
    500: '#667eea',
    600: '#5a67d8',
    700: '#4c51bf',
    800: '#434190',
    900: '#3c366b',
  },
  purple: {
    100: '#faf5ff',
    200: '#e9d8fd',
    300: '#d6bcfa',
    400: '#b794f4',
    500: '#9f7aea',
    600: '#805ad5',
    700: '#6b46c1',
    800: '#553c9a',
    900: '#44337a',
  },
  pink: {
    100: '#fff5f7',
    200: '#fed7e2',
    300: '#fbb6ce',
    400: '#f687b3',
    500: '#ed64a6',
    600: '#d53f8c',
    700: '#b83280',
    800: '#97266d',
    900: '#702459',
  },
};
const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1440px',
  xl3: '1600px',
};
const pristineConfig = {
  themes: [
      'marketing',
      'studio',
      'data',
      'digital',
  ],
  screens,
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
  },
  bootstrapMaxWidths: {
    ...screens,
  },
  gutters: {
    none: 0,
    base: '15px',
    md: '30px',
    lg: '50px',
    xl: '70px',
    xxl: '100px',
  },
  units: {
    '2xl': '96px',
    xl: '72px',
    lg: '64px',
    md: '48px',
    base: '32px',
    sm: '24px',
    xs: '16px',
  },
  colors: {
    primary: {
      base: '#0e3d55',
      light: '#c2cad2',
      dark: '#00172C',
    },
    secondary: {
      base: '#ec6649',
      light: '#F3A08E',
      dark: '#D33816',
    },
    communication: {
      base: '#C4B9E3',
    },
    marketing: {
      base: '#EC6649',
      light: '#F3A08E',
      dark: '#D33816',
    },
    studio: {
      base: '#75C6CB',
      light: '#C7E8EA',
      dark: '#3B929B',
    },
    data: {
      base: '#CDDC82',
      light: '#E5EDBE',
      dark: '#BBC877',
    },
    digital: {
      base: '#EAB871',
      light: '#F4D9B4',
      dark: '#E0972E',
    },
    red: {
      ...defaultColors.red,
      base: '#E7334B',
    },
    green: {
      ...defaultColors.green,
      base: '#56AF31',
    },
    gray: {
      ...defaultColors.gray,
      black: '#282D35',
      dark: '#8492A6',
      medium: '#BEC5D0',
      light: '#EFF2F7',
      white: '#FFFFFF',
    },
  },
  spacing: {
    '5xl': '200px',
    '4xl': '100px',
    '3xl': '75px',
    '2xl-cards': '60px',
    '2xl': '50px',
    xl: '40px',
    lg: '30px',
    md: '20px',
    base: '15px',
    sm: '10px',
    xs: '3px',
    none: 0,
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {},
  borderWidth: {},
  boxShadow: {
    base: '4px 4px 0px rgba(190, 197, 208, 0.5)',
    primary:
      '4px 4px 0px rgba(190, 197, 208, 0.5), 5px 5px 10px rgba(243, 160, 142, 0.8)',
  },
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    sans: [
      'Century Gothic',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: [
      'PT Serif',
      'Georgia',
      'Cambria',
      '"Times New Roman"',
      'Times',
      'serif',
    ],
  },
  fontSize: {
    '4xl': '48.83px',
    '3xl': '39.06px',
    '2xl': '31.25px',
    xl: '25px',
    lg: '20px',
    md: '18px',
    base: '16px',
    sm: '14px',
    xs: '12px',
    '2xs': '10px',
    '3xs': '7px',
  },
  fontWeight: {},
  height: {
    '3/4': '75%',
    'jobHero': '500px',
    'testimonials': '725px',
    'relations': '370px',
  },
  inset: {},
  letterSpacing: {
    lg: '1px',
    base: '0.15px',
  },
  lineHeight: {
    base: '22px',
  },
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {
    'jobSection': '125px',
  },
  stroke: {},
  textColor: {},
  width: {
    'jobHero': '1680px',
    'csContainer': '90%',
  },
  zIndex: {
    '-1': '-1',
  },
  transition: {
    base: 'all .2s',
  },
};

module.exports = pristineConfig;
