// import Vue from 'vue';
// import App from './App.vue';
// import './plugins/element';
//
// Vue.config.productionTip = false;
//
// new Vue({
//   render: (h) => h(App),
// }).$mount('#app');

import Scroller from '@/plugins/scroller';

const scroller: any = new Scroller();

(window as any).scroller = scroller;
